import React from "react";
import { ASSETS } from "../images";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-header">
        <div style={{ flex: 1 }}>
          <img alt="logo" height="60px" src={ASSETS.images.logo} />
        </div>
        <div className="footer-menu">
          <div>
            <a href="/#what_we_do">What we do</a>
          </div>
          <div>
            <a href="/#why_us">Why us?</a>
          </div>
          <div>
            <a href={"#contact"}>Contact</a>
          </div>
        </div>
      </div>
      <div className="footer-middle-section">
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ alignContent: "center" }}>
            <i
              className="fa-solid fa-phone"
              style={{ fontSize: "30px", alignItems: "center" }}
            />
          </div>
          <div style={{ padding: "0px 15px" }}>
            <div>Contact</div>
            <div style={{ marginTop: "5px" }}>+91 9587308126</div>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex" }}>
          <div style={{ alignContent: "center" }}>
            <i
              className="fa-solid fa-envelope"
              style={{ fontSize: "30px", alignItems: "center" }}
            />
          </div>
          <div style={{ padding: "0px 15px" }}>
            <div>Email</div>
            <div style={{ marginTop: "5px" }}>help@beintelligent.com</div>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex" }}>
          <div style={{ alignContent: "center" }}>
            <i
              className="fa-solid fa-location-dot"
              style={{ fontSize: "30px", alignItems: "center" }}
            />
          </div>
          <div style={{ padding: "0px 15px" }}>
            <div>Address</div>
            <div style={{ marginTop: "5px" }}>
              156, B-block Hiran Magri Sec-14 Udaipur (Raj) 313002
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom-section">
        <div style={{ marginRight: "15px" }}>
          <a href="/privacy-policy ">Privacy Policy</a>
        </div>
        <div>
          <a href="/terms-and-conditions ">Terms and Condition</a>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          © 2024 Beintelligent. All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
