import logo from "./logo.png";
import heroImage from "./com.png";
import feature1 from "./catalog-management.png";
import feature2 from "./centralised-data.png";
import feature3 from "./versatile-compatibility.png";
import feature4 from "./24-7-support.png";
import feature5 from "./service-oriented.png";
import AboutUs from "./about-us.png";

export const ASSETS = {
  images: {
    logo: logo,
    heroImage,
    feature1,
    feature2,
    feature3,
    feature4,
    feature5,
    AboutUs
  },
};
