import * as yup from "yup";

const contactValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  phone_number: yup
    .string()
    .required("phone number is required")
    .typeError("phone number is required"),
  email: yup
    .string()
    .required("email is required")
    .email()
    .typeError("email is required"),
  message: yup
    .string()
    .required("message is required")
    .typeError("message is required"),
});

export { contactValidation };
