import React from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { httpClient } from "../httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { contactValidation } from "../validations";
// import Header from "../component/Header";
// import Footer from "../component/Footer";

function Contact() {
  const submitFormApiStatus = useCallbackStatus();
  const { enqueueSnackbar } = useSnackbar();

  const { values, errors, handleChange, resetForm, submitForm } = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      phone_number: "",
      type: "store",
    },
    validationSchema: contactValidation,
    onSubmit: async () => {
      await submitFormApiStatus.run(
        httpClient("POST", "/user/contact", {
          body: values,
        })
          .then((result) => {
            enqueueSnackbar({
              message: "Message Sent Successfully",
              variant: "success",
            });
            resetForm();
          })
          .catch((error) => {
            enqueueSnackbar({
              message: "Something went wrong",
              variant: "error",
            });
          })
      );
    },
  });

  return (
    <>
      {/* <Header /> */}
      <div className="content">
        <div
          style={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
            padding: "30px",
          }}
        >
          Contact Us
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 contact-container">
              <div
                style={{ display: "flex", flexDirection: "column", gap: 30 }}
              >
                <div className="contact-card">
                  <div className="input-label">Email</div>
                  <div>support@beintelligent.co.in</div>
                </div>
                <div className="contact-card">
                  <div className="input-label">Phone Number</div>
                  <div>+91 9587308126</div>
                </div>
                <div className="contact-card">
                  <div className="input-label">Address</div>
                  <div>
                    156, B-block Hiran Magri Sec-14 Udaipur (Raj) 313002
                  </div>
                </div>
              </div>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226.83213258655078!2d73.69504613497013!3d24.54380480528875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967ef059f4fa445%3A0x6c4564318cc0450d!2sJain%20Electrical!5e0!3m2!1sen!2sin!4v1719661132976!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0,padding:"0px 30px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
            <div className="col-md-6">
              <div>
                We support you in all areas and try to meet your store
                requirements in the best way possible.
              </div>
              <div style={{ padding: "10px 0px" }}>
                <input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className={`input ${errors.name && 'error'}`}
                  placeholder="Full Name"
                />
                {errors.name ? <div style={{color:"red",fontSize:"13px"}}>{errors.name}</div> : ""}
                <box></box>
              </div>
              <div style={{ padding: "10px 0px" }}>
                <input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  className={`input ${errors.name && 'error'}`}
                  placeholder="Email"
                />
                {errors.email ? <div style={{color:"red",fontSize:"13px"}}>{errors.email}</div> : ""}
              </div>
              <div style={{ padding: "10px 0px" }}>
                <input
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  className={`input ${errors.name && 'error'}`}
                  placeholder="Contact number"
                />
                {errors.phone_number ? <div style={{color:"red",fontSize:"13px"}}>{errors.phone_number}</div> : ""}
              </div>
              <div style={{ padding: "10px 0px" }}>
                <textarea
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  rows={5}
                  className={`input ${errors.name && 'error'}`}
                  placeholder="Message"
                ></textarea>
                {errors.message ? <div style={{color:"red",fontSize:"13px"}}>{errors.message}</div> : ""}
              </div>

              <div>
                <button
                  disabled={submitFormApiStatus.isPending}
                  onClick={submitForm}
                  className="btn btn-submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Contact;
