import Footer from "../component/Footer";
import Header from "../component/Header";
import { ASSETS } from "../images";
import Contact from "./Contact";

function Home() {
  return (
    <>
      <div className="hero-section">
        <Header />
        <div className="banner">
          <div style={{ flex: 1 }}>
            <div className="hero-section-content">
              <div className="hero-section-tagline">
                Elevate Your Business with Custom-Tailored Solutions
              </div>
              <div style={{ margin: "10px 0px" }}>
                Unlock your business potential with personalized strategies
                designed to drive success.
              </div>
              <div style={{ paddingTop: "10px" }}>
                <button className="button">
                  Let's Talk{" "}
                  <i
                    style={{ marginLeft: "10px" }}
                    className="fa-solid fa-arrow-right"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="hero_section_image_container">
            <img
              alt="hero-section"
              className="hero-section-image"
              src={ASSETS.images.heroImage}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
        }}
      >
        <div id="what_we_do" style={{ fontWeight: 600, fontSize: "35px" }}>
          What we do
        </div>
        <div className="section-subheading" style={{ textAlign: "center" }}>
          Delivering innovative, customized solutions to meet your unique
          business needs and drive growth.
        </div>
      </div>

      <div className="feature feature-reverse">
        <div style={{ flex: 1, alignContent: "center" }}>
          <div style={{ fontWeight: 600, fontSize: "25px" }}>
            Catalog Management
          </div>
          <div style={{ padding: "10px 0px" }}>
            Empower users to effortlessly manage their product catalogs
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <img
            alt="catalog-mangement"
            className="section-image"
            src={ASSETS.images.feature1}
          />
        </div>
      </div>

      <div className="feature">
        <div style={{ flex: 1 }}>
          <img
            alt="centralised-data"
            className="section-image"
            src={ASSETS.images.feature2}
          />
        </div>
        <div style={{ flex: 1, alignContent: "center" }}>
          <div style={{ fontWeight: 600, fontSize: "25px" }}>
            Centralized Data Management
          </div>
          <div style={{ padding: "10px 0px" }}>
            Consolidate all business-related data in one place for seamless
            access and control.
          </div>
        </div>
      </div>

      <div className="feature feature-reverse">
        <div style={{ flex: 1, alignContent: "center" }}>
          <div style={{ fontWeight: 600, fontSize: "25px" }}>
            Versatile Compatibility
          </div>
          <div style={{ padding: "10px 0px" }}>
            Suitable for both inventory and non-inventory based businesses
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <img
            alt="versatile-compatibility"
            className="section-image"
            src={ASSETS.images.feature3}
          />
        </div>
      </div>

      <div className="feature">
        <div style={{ flex: 1 }}>
          <img
            alt="support"
            className="section-image"
            src={ASSETS.images.feature4}
          />
        </div>
        <div style={{ flex: 1, alignContent: "center" }}>
          <div style={{ fontWeight: 600, fontSize: "25px" }}>
            24/7 Training and Assistance
          </div>
          <div style={{ padding: "10px 0px" }}>
            Continuous support to ensure your success
          </div>
        </div>
      </div>

      <div className="feature feature-reverse">
        <div style={{ flex: 1, alignContent: "center" }}>
          <div style={{ fontWeight: 600, fontSize: "25px" }}>
            Service-Oriented Businesses
          </div>
          <div style={{ padding: "10px 0px" }}>
            Ideal for service providers looking to elevate their operations to
            meet modern market standards
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <img
            alt="service-oriented"
            className="section-image"
            src={ASSETS.images.feature5}
          />
        </div>
      </div>

      <div className="why-us-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="why_us" style={{ fontSize: "25px", fontWeight: 600 }}>
            Why us?
          </div>
          <div style={{ padding: "20px" }}>
            {/* Rerum aliquid voluptates illum incidunt nisi. */}
          </div>
        </div>
        <div className="container-flex">
          <div className="row">
            <div className="col-md-6">
              <div style={{ paddingTop: "70px" }}>
                <i className="fa-solid fa-phone" style={{ fontSize: "30px" }} />
              </div>
              <div style={{ fontWeight: 600, marginTop: "20px" }}>
                Flexibility and Adaptability
              </div>
              <div>
                Our business tools offer extensive customization options and
                seamless integrations with other software systems, enabling you
                to tailor solutions to your specific needs and workflows. This
                flexibility allows businesses to adapt to changing market
                conditions and maintain agility in the face of uncertainty.
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ paddingTop: "70px" }}>
                <i className="fa-solid fa-phone" style={{ fontSize: "30px" }} />
              </div>
              <div style={{ fontWeight: 600, marginTop: "20px" }}>
                Cost Savings
              </div>
              <div>
                Investing in our solutions can lead to significant cost
                reductions over time. By automating manual processes, minimizing
                errors, and enhancing efficiency, organizations can reduce
                operational costs and allocate resources more effectively.
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ paddingTop: "70px" }}>
                <i className="fa-solid fa-phone" style={{ fontSize: "30px" }} />
              </div>
              <div style={{ fontWeight: 600, marginTop: "20px" }}>
                Increased Efficiency
              </div>
              <div>
                Our tools automate repetitive tasks, streamline processes, and
                eliminate manual errors, thereby saving time and boosting
                productivity. With automation in areas like data entry,
                reporting, and communication, employees can focus on more
                strategic and value-added activities.
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ paddingTop: "70px" }}>
                <i
                  style={{ fontSize: "30px" }}
                  className="fa-solid fa-shield-halved"
                />
              </div>
              <div style={{ fontWeight: 600, marginTop: "20px" }}>
                Secure Access Anytime, Anywhere
              </div>
              <div>
                Enjoy the convenience and security of accessing your business
                tools from any location, at any time.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="about_us"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
        }}
      >
        <div style={{ fontWeight: 600, fontSize: "35px" }}>About Us</div>
      </div>
      <div className="about-us-container">
        <div style={{ flex: 1, padding: "0px 50px" }}>
          <div style={{ fontWeight: 600, fontSize: "25px" }}>
            We Help You to Streamline Your Business with Our All-in-One B2B
            Management Platform
          </div>
          <div style={{ padding: "10px 0px" }}>
            Beintelligent Business Solution offers a comprehensive B2B
            management platform designed to help you manage your business with
            unparalleled efficiency. From inventory management to customer
            relationship management, we have all your needs covered.
          </div>
          <div style={{ paddingTop: "30px" }}>
            <button className="button">
              Let's Talk{" "}
              <i
                style={{ marginLeft: "10px" }}
                className="fa-solid fa-arrow-right"
              />
            </button>
          </div>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <img
            alt="about-us-image"
            className="about-us-image"
            src={ASSETS.images.AboutUs}
          />
        </div>
      </div>

      <div id="contact">
        <Contact/>
      </div>

      <Footer />
    </>
  );
}

export default Home;
