import React from "react";
import { ASSETS } from "../images";

function Header() {
  function sideMenu() {
    document.getElementById("main-menu").classList.toggle("hide");
  }

  return (
    <div className="header">
      <div style={{ flex: 1 }}>
        <a href="/">
          <img alt="Logo" height="60px" src={ASSETS.images.logo} />
        </a>
      </div>
      <div className="responsive-menu-bar">
        <div onClick={sideMenu}>
          <i style={{ fontSize: "25px" }} className="fa-solid fa-bars" />
        </div>
      </div>
      <div id="main-menu" className="menu hide">
        <div className="menu-item">
          <a href="/#what_we_do">What we do</a>
        </div>
        <div className="menu-item">
          <a href="/#why_us">Why us?</a>
        </div>
        <div className="menu-item">
          <a href="/#about_us">About us</a>
        </div>
        <div className="menu-item">
          <a href={"/#contact"}>Contact</a>
        </div>
        <div className="menu-item">
          <a href="https://partner.beintelligent.co.in">Login /sign up</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
